import React from 'react'
import styles from './styles.module.css'
import { useTranslation} from 'react-i18next'
import rob1 from '../../assets/HOWITWORKS/rob1.jpg'
import rob2 from '../../assets/HOWITWORKS/rob2.jpg'
import rob3 from '../../assets/HOWITWORKS/rob3.jpg'
function HOWITWORKS() {
  const [t]=useTranslation();//translation hook
  return (
    <div style={{color:"var(--wgrey)"}}>
      {/* three cards section */}
      <p className="landingTitle" style={{marginBottom:"0.25rem"}}>{t("Ready to See the Big Picture?")}<br/>{t("Getting started is as easy as 1, 2, 3")}</p>
      <p style={{textAlign:"center",fontSize:"1.125rem",marginBottom:"2rem"}}>{t("Create an account. Provide Field Boundaries & Planting Dates,")}<br/>{t("View the best digital field insights you’ve seen.")}</p>
      <section className={`container ${styles.cardsContainer}`}>
        <div>
          <div className={styles.numbering}>1</div>
          <h3>{t("ACQUISITION")}</h3>
          <p>
          {t("We’ve built a strong and proven content network across millions of acres made possible through skilled operators and proprietary logistics. Satellites, planes and drones enable data acquisition at all levels.")} 
          </p>
          <br/>
          <p>
          {t("This delivers indisputable leaf-level ground truth powered by submillimeter resolution and has built the largest and richest crop library in the business.")}
          </p>
        </div>
        <div>
          <div className={styles.numbering}>2</div>
          <h3>{t("ANALYTICS")}</h3>
          <p>
          {t("We’ve amassed 50 million+ submillimeter high-resolution images. But what good are pictures unless they’re actionable?")} 
          </p>
          <br/>
          <p>
          {t("100+ agronomists help digitize this knowledge and run it through our AI engine, leveraging Machine Learning (ML) and state-of-the-art Computer Vision (CV), identifying actionable and constantly improving insights at scale.")}
          </p>
        </div>
        <div>
          <div className={styles.numbering}>3</div>
          <h3>{t("ACTION")}</h3>
          <p>
          {t("We take all of this and provide unparalleled convenience through the best and easiest-to-use digital Ag software in use today.")}
          </p>
          <br/>
          <p>
          {t("Whether creating zones, prescriptions or recommendations, we make it seamless for advisors and growers to engage, immerse and collaborate on insight-inspired actions to improve crop performance.")}
          </p>
        </div>
      </section>
      
      {/* MULTI-LEVEL CONTENT ACQUISITION section */}
      <section className={styles.bContainer} style={{backgroundColor:"var(--bg-lgrey)",padding:"2rem 0",borderBottom:"2px solid var(--border-grey)",borderTop:"2px solid var(--border-grey)"}}>
        <div className={`container ${styles.benefits}`}>
          <div>
            <h2 style={{color:"var(--main-color)"}}>{t("MULTI-LEVEL CONTENT ACQUISITION")}</h2>
            <p>{t("THE EGROBOTS DIFFERENCE:")}</p>
            <ul>
              <li>
                {t("Our submillimeter image resolution is so clear you can distinguish between different insect damages, lesions, different nutrient deficiencies, weed species and more.")}
              </li>
              <li>
              {t("Full-service delivery means no flying, uploading, data stitching or hardware failures.")}
              </li>
              <li>
              {t("Delivery and data at scale means no matter how many acres, no matter where you are, we execute seamlessly to provide effortless and valuable insights.")}
              </li>
            </ul>
          </div>
          <div>
            <img src={rob1} alt="robot-image" />
          </div>
        </div>
      </section>

      {/*DEEP AI & MACHINE LEARNING section */}
      <section className={styles.bContainer} style={{padding:"2rem 0",borderBottom:"2px solid var(--border-grey)"}}>
        <div className={`container ${styles.benefits}`} style={{flexDirection:"row-reverse"}}>
          <div>
            <h2 style={{color:"var(--main-color)"}}>{t("DEEP AI & MACHINE LEARNING")}</h2>
            <p>{t("THE EGROBOTS DIFFERENCE:")}</p>
            <ul>
              <li>
              {t("50M images and billions of data points enrich insights and accuracy constantly.")}
              </li>
              <li>
              {t("Multi-layer analysis of imagery (satellite + drone or airplane) provides unmatched insight context and quality.")}
              </li>
              <li>
              {t("Agronomy experts around the world inform and train proprietary AI and ML algorithms perfecting current knowledge and enabling adaptability for new threats.")}
              </li>
            </ul>
          </div>
          <div className={styles.evenSection}>
            <img src={rob2} alt="robot-image" />
          </div>
        </div>
      </section>

      {/* ACTION DRIVEN OPEN PLATFORM section */}
      {/* <section className={styles.bContainer}  style={{backgroundColor:"var(--bg-lgrey)",padding:"2rem 0"}}>
        <div className={`container ${styles.benefits}`}>
          <div>
            <h2 style={{color:"var(--main-color)"}}>{t("ACTION DRIVEN OPEN PLATFORM")}</h2>
            <p>{t("THE EGROBOTS DIFFERENCE:")}</p>
            <ul>
              <li>
              {t("Software designed to facilitate the engagement between advisors and growers.")}
              </li>
              <li>
              {t("Open architecture means ability to collaborate with other on-farm partners.")}
              </li>
              <li>
              {t("Our mobile-first approach ensures you get proactive smart alerts so you have the insights you need without having to search..")}
              </li>
            </ul>
          </div>
          <div>
            <img src={rob3} alt="robot-image" />
          </div>
        </div>
      </section> */}
    </div>
  )
}

export default HOWITWORKS
