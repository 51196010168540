import './App.css';
import {Route,Routes} from 'react-router-dom'
import Header from './components/shared/Header'
import Solutions from './components/Solutions';
import Footer from './components/shared/Footer';
import HOWITWORKS from './components/HOWITWORKS';
import ABOUT from './components/ABOUT';
import CONTACTUS from './components/CONTACTUS';
function App() {
  return (
    // components routes
    <>
      <Header/>
      <Routes>
        <Route path="/" element={<Solutions/>}></Route>
        <Route path="how-it-works" element={<HOWITWORKS/>}></Route>
        <Route path='about-us' element={<ABOUT/>}></Route>
        <Route path='contact-us' element={<CONTACTUS/>}></Route>
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
