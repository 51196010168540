import React,{useState} from 'react'
import styles from './styles.module.css'
import {useTranslation} from 'react-i18next'
//react icons
import {IoBulbOutline,IoSettingsOutline} from 'react-icons/io5'
import {BsClipboardData,BsTree,BsSun,BsPeople} from 'react-icons/bs'
import {BiTargetLock} from 'react-icons/bi'
import {MdOutlineGppGood} from 'react-icons/md'

function Tabs() {
  const [tabIndex,setTabIndex]=useState(0); //index of active tab
  const[t]=useTranslation();//translation hook
  
  return (
    <>
      {/* buttons for changing tab */}
      <div className={styles.buttonsContainer}>
        <button onClick={()=>setTabIndex(0)} className={tabIndex==0?styles.activeButton:''}>{t("GROWER ADVANTAGES")}</button>
        <button onClick={()=>setTabIndex(1)} className={tabIndex==1?styles.activeButton:''}>{t("TRUSTED ADVISOR ADVANTAGES")}</button>
      </div>

      {/* first tab renered when index=0 */}
      <div className={tabIndex==0?`${styles.active} ${styles.tab}`:`${styles.tab}`}>
        <div>
        <div style={{fontSize:"145px",color:"var(--headings-color)",display:"flex"}}>
          <IoBulbOutline/>
        </div>
          <div>
            <h2>{t("UNBIASED ACTIONABLE INSIGHTS")}</h2>
            <p>{t("No more guesswork or worrying about whether you really need the inputs you’re being sold. Clear, undisputed insights brought by a trusted advisor let you act with confidence.")}</p>
          </div>
        </div>
        <div>
          <div style={{fontSize:"145px",color:"var(--headings-color)",display:"flex"}}>
            <IoSettingsOutline/>
          </div>
          <div>
            <h2>{t("MORE EFFICIENT USE OF RESOURCES")}</h2>
            <p>{t("More targeted insights mean more targeted prescriptions. Buy what you use and use what you buy — more efficient, more sustainable intervention.")}</p>
          </div>
        </div>
        <div>
        <div style={{fontSize:"145px",color:"var(--headings-color)",display:"flex"}}>
          <BsTree/>
        </div>
          <div>
            <h2>{t("HEALTHIER CROPS & HIGHER YIELD POTENTIAL")}</h2>
            <p>{t("Earlier, more accurate and more precise intervention made possible by unmatched insights and AI knowledge means better plant health and yield.")}</p>
          </div>
        </div>
        <div>
          <div style={{fontSize:"145px",color:"var(--headings-color)",display:"flex"}}>
            <BsClipboardData/>
          </div>
          <div>
            <h2>{t("BETTER YEAR-ROUND PLANNING")}</h2>
            <p>{t("The story of your fields is always at your fingertips. Now, you have the information you need for analysis and predictive planning.")}</p>
          </div>
        </div>
      </div>

      {/* second tab renered when index=1 */}
      <div className={tabIndex==1?`${styles.active} ${styles.tab}`:`${styles.tab}`}>
        <div>
          <div style={{fontSize:"145px",color:"var(--headings-color)",display:"flex"}}>
            <MdOutlineGppGood/>
          </div>
          <div>
            <h2>{t("BRAND EXPERIENCE & DIFFERENTIATION")}</h2>
            <p>{t("RoboScout® and Shagarah™ empower you with the knowledge and insights about your growers and their crops. Simply put, you set the pace and the competition will never catch up.")}</p>
          </div>
        </div>
        <div>
          <div style={{fontSize:"145px",color:"var(--headings-color)",display:"flex"}}>
            <BsPeople/>
          </div>
          <div>
            <h2>{t("UNBIASED & TRUSTED ENGAGEMENT")}</h2>
            <p>{t("You’re not just another sales call. You’re the trusted advisor he’s waiting to hear from, and reaches out to, because RoboScout® gives you something worth talking about.")}</p>
          </div>
        </div>
        <div>
          <div style={{fontSize:"145px",color:"var(--headings-color)",display:"flex"}}>
            <BiTargetLock/>
          </div>
          <div>
            <h2>{t("TARGETED SOLUTIONS INCREASE RETAILER & GROWER PROFITABILITY")}</h2>
            <p>{t("One size never fits all. Now you can bring more precise inputs that are specifically tailored to the issue at hand. The right solution means more profit and opportunity.")}</p>
          </div>
        </div>
        <div>
          <div style={{fontSize:"145px",color:"var(--headings-color)",display:"flex"}}>
            <BsSun/>
          </div>
          <div>
            <h2>{t("BRING SCALE TO YOUR TEAM")}</h2>
            <p>{t("Cover more ground with your sales agronomy team. Scout from anywhere, anytime. Know where to go — prioritize grower fields requiring the most urgent attention.")}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Tabs
