import React,{useRef,useState} from 'react'
import styles from './styles.module.css'
import {useTranslation} from 'react-i18next'
import banner from '../../assets/CONTACTUS/banner.jpg'
import emailjs from 'emailjs-com'
import { FaCheckCircle } from "react-icons/fa";
import { useEffect } from 'react'

function CONTACTUS() {
  const [done,setDone]=useState(false);//form submission indicator for user
  const [t]=useTranslation();
  const form=useRef(null);

  const sendEmail=(e)=>{
    e.preventDefault();
    //emailjs librar method to sen email from client side
    emailjs.sendForm("service_k3j566b","template_1ishmky",form.current,"bTZzM2XR5ysX_7G9X")
    .then(res=>{form.current.reset();setDone(true)})//set form as form is submitted
    .catch(e=>console.log(e))
  }

  useEffect(()=>{
    if (done){
      const timeOut=setTimeout(()=>{
        setDone(false) //hide submission indicator after 3 seconds
      },3000)
      return ()=>clearInterval(timeOut)
    }
  },[done])
  return (
    <div className={`container ${styles.contactContainer}`} style={{color:"var(--main-color)"}}>
      <div>
        <p>{t("To schedule a demo, or to speak with a Egrobots representative, please complete the form below and we’ll contact you shortly.")}</p>
        <form onSubmit={sendEmail} ref={form}>
          <fieldset>
            <div>
              <label htmlFor="firstName">{t("first name")}<span style={{color:"red"}}>*</span></label>
              <input type="text" name="firstName"/>
            </div>
            <div>
              <label htmlFor="lastName">{t("last name")}<span style={{color:"red"}}>*</span></label>
              <input type="text" name="lastName" />
            </div>
          </fieldset>
          <fieldset>
            <div>
              <label htmlFor="email">{t("email")}<span style={{color:"red"}}>*</span></label>
              <input type="text" name="email"/>
            </div>
            <div>
              <label htmlFor="phoneNumber">{t("phone number")}<span style={{color:"red"}}>*</span></label>
              <input type="text" name="phoneNumber"/>
            </div>
          </fieldset>
          <fieldset>
            <label htmlFor="job">{t("i am a")}</label>
            <select name="job">
              <option value="Agronomy Advisor">{t("Agronomy Advisor")}</option>
              <option value="Ag Retailer">{t("Ag Retailer")}</option>
              <option value="Grower">{t("Grower")}</option>
              <option value="Other">{t("Other")}</option>
            </select>
          </fieldset>
          <fieldset>
            <label htmlFor="acresNumber">{t("If you are a grower, how many acres do you farm?")}</label>
            <input type="text" name='acresNumber'/>
          </fieldset>
          <fieldset>
            <label htmlFor="corps">{t("Which crops do you farm?")}</label>
            <div>
              <input type="checkbox" name='mango' value="checked"/>
              <label htmlFor="mango">{t("Mango")}</label>
            </div>
            <div>
              <input type="checkbox" name='palm' value="checked"/>
              <label htmlFor="Palm">{t("Palm")}</label>
            </div>
            <div>
              <input type="checkbox" name="apple" value="checked"/>
              <label htmlFor="">{t("Apple")}</label>
            </div>
            <div>
              <input type="checkbox" name="corn" value="checked"/>
              <label htmlFor="">{t("Corn")}</label>
            </div>
            <div>
              <input type="checkbox" name="grape" value="checked"/>
              <label htmlFor="">{t("Grape")}</label>
            </div>
            <div>
              <input type="checkbox" name="pepper" value="checked"/>
              <label htmlFor="">{t("Peper")}</label>
            </div>
            <div>
              <input type="checkbox" name="strawberry" value="checked"/>
              <label htmlFor="">{t("Strawberry")}</label>
            </div>
            <div>
              <input type="checkbox" name="tomato" value="checked"/>
              <label htmlFor="">{t("Tomato")}</label>
            </div>
            <div>
              <input type="checkbox" name="citrus" value="checked"/>
              <label htmlFor="">{t("Citrus")}</label>
            </div>
            <div>
              <input type="checkbox" name="other" value="checked"/>
              <label htmlFor="">{t("Other")}</label>
            </div>
          </fieldset>
          <div>
            <button style={{marginRight:"0.5rem",backgroundColor:"var(--headings-color)",width:"fit-content",color:"white",padding:"0.5rem",cursor:"pointer",border:"0"}}>{t("Schedule Demo")}</button>
            {done&&<FaCheckCircle style={{color:"var(--headings-color)",fontSize:"1.2rem"}}/>}
          </div>
        </form>
      </div>
      <div>
        <div>
          <h3 style={{color:"var(--headings-color)"}}>{t("CONTACT US")}</h3>
          <h4><strong style={{color:"var(--headings-color)"}}>{t("CALL")}</strong> {t("+20-155-3718894")}</h4>
          <h4><strong style={{color:"var(--headings-color)"}}>{t("EMAIL")}</strong> info@egrobots.com</h4>
        </div>
        <img src={banner} alt="contact-banner" />
      </div>
    </div>
  )
}

export default CONTACTUS
