import React from 'react'
import styles from './styles.module.css'
import Carousel from 'react-multi-carousel'; //responsive carousel licrary
import { MdPlayCircleOutline} from "react-icons/md"; //video play icon
import Accordionn from './Accordionn'
import VideoPlayer from './VideoPlayer';
import Tabs from './Tabs';
import {useTranslation} from 'react-i18next'
import MediaQuery from 'react-responsive' //library for renering components and tags based on media query
import mobapp from '../../assets/SOLUTIONS/mobapp.gif'
import webapp from '../../assets/SOLUTIONS/webapp.png'
import c1 from '../../assets/SOLUTIONS/ctrees/c1.png'
import c2 from '../../assets/SOLUTIONS/ctrees/c2.png'
import c3 from '../../assets/SOLUTIONS/ctrees/c3.png'
import c4 from '../../assets/SOLUTIONS/ctrees/c4.png'
import c5 from '../../assets/SOLUTIONS/ctrees/c5.png'
import c6 from '../../assets/SOLUTIONS/ctrees/c6.png'
import roborvid from "../../assets/SOLUTIONS/roborvid2.PNG"
import servicevid from "../../assets/SOLUTIONS/servicevid.png"
import { NavLink } from 'react-router-dom';

function Solutions() {
  const [t,i18n]=useTranslation();//translation hook

  //number of images depending on media query
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5 //move five items when sliding
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 ////move three items when sliding
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return (
    <>
      {/* first section */}
      <div className="landingTitle" id="connect">
        <p>{t("WHAT GROWERS DESERVE AND TRUSTED ADVISORS DEMAND")}</p>
      </div>
      <div className={styles.benefitsCards}>
        <div>
          <h2 style={{ display: "flex", justifyContent: "center" }}>
            ROBOSCOUT
            <sup style={{ order: i18n.language == "ar" ? "-1" : "1" }}>®</sup>
          </h2>
          <p>
            {t(
              "What you can’t see can hurt you. Take your agronomy to a whole new level."
            )}
          </p>
          <ul>
            <li>
              {t(
                "Ground truth with leaf-level precision and unmatched clarity."
              )}
            </li>
            <li>
              {t(
                "Be at the right place at the right time with broadacre coverage."
              )}
            </li>
            <li>{t("Get speed and accuracy with AI-driven insights.")}</li>
          </ul>
          <div
            style={{
              flex: "1 1 auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={webapp} alt="webapp" />
          </div>
        </div>
        <div>
          <h2>{t("SHAGARAH™")}</h2>
          <p>
            {t(
              "Free yourself with Shagarah™, the perfect companion to RoboScout®"
            )}
          </p>
          <ul>
            <li>{t("Immediate diseases detection and recommenditions.")}</li>
            <li>{t("Vegetation,Moisture,and Weather updates.")}</li>
            <li>{t("Expert consultion with action plan.")}</li>
          </ul>
          <div
            className={styles.mob}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                flex: "1 1 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={mobapp} alt="mobileapp" />
            </div>
          </div>
        </div>
      </div>

      {/* carousel section */}
      <div className="landingTitle">
        <p>{t("LEAF-LEVEL PRECISION ENABLES FAST, DECISIVE ACTION")}</p>
      </div>
      <div className="container" style={{ position: "relative" }}>
        {/* show dots of images - autoPlay carousel - infinite play - dots outside carousel - speed of carousek */}
        <Carousel
          responsive={responsive}
          showDots={true}
          renderDotsOutside={true}
          autoPlay={true}
          infinite={true}
          dotListClass={styles.dot}
          autoPlaySpeed={5000}
          transitionDuration={500}
          containerClass="carousel-container"
        >
          <div className={styles.cImageContainer}>
            <img src={c1} alt="carousel-img" />
          </div>
          <div className={styles.cImageContainer}>
            <img src={c2} alt="carousel-img" />
          </div>
          <div className={styles.cImageContainer}>
            <img src={c3} alt="carousel-img" />
          </div>
          <div className={styles.cImageContainer}>
            <img src={c4} alt="carousel-img" />
          </div>
          <div className={styles.cImageContainer}>
            <img src={c5} alt="carousel-img" />
          </div>
          <div className={styles.cImageContainer}>
            <img src={c6} alt="carousel-img" />
          </div>
        </Carousel>
      </div>

      {/* videos section */}
      <div className="landingTitle" id="see-yourself">
        {t("SEE FOR YOURSELF")}
      </div>
      <div className={`container ${styles.videoContainer}`}>
        {/* <div className={`${styles.videoCover} ${styles.firstVideo}`} >
              <VideoPlayer url="https://www.youtube.com/watch?app=desktop&v=4IZn_xDOEwU&t=1s" imageUrl={roborvid} icon={<span className={styles.playVideo} ><MdPlayCircleOutline/></span>}/>
            </div> */}
        <div className={`${styles.videoCover} ${styles.firstVideo}`}>
          {/* passing video url-image-play icon as props*/}
          {i18n.language === "en" && (
            <VideoPlayer
              url="https://www.youtube.com/watch?app=desktop&v=xBYwkjQx1MQ&feature=emb_logo"
              // url="//www.youtube.com/embed/xBYwkjQx1MQ"
              imageUrl={roborvid}
              icon={
                <span className={styles.playVideo}>
                  <MdPlayCircleOutline />
                </span>
              }
            />
          )}
          {i18n.language === "ar" && (
            <VideoPlayer
              url="https://www.youtube.com/watch?app=desktop&v=l86olJcuHgA&feature=emb_logo"
              imageUrl={roborvid}
              icon={
                <span className={styles.playVideo}>
                  <MdPlayCircleOutline />
                </span>
              }
            />
          )}
        </div>
        <div>
          <div className={`${styles.videoCover} ${styles.secondVideo}`}>
            <VideoPlayer
              url="https://www.youtube.com/watch?app=desktop&v=E2oUDrn2fxI&feature=emb_logo"
              imageUrl={servicevid}
              icon={
                <span className={styles.playVideo}>
                  <MdPlayCircleOutline />
                </span>
              }
            />
          </div>
        </div>
      </div>

      {/* tabs section */}
      <div
        className={`container ${styles.tabsContainer}`}
        style={{ marginTop: "3rem" }}
      >
        <Tabs />
      </div>

      {/* cards section */}
      <div className="landingTitle">
        <p>{t("FLEXIBLE PLANS TO MEET RETAILER AND GROWER NEEDS")}</p>
      </div>
      <div className={`container ${styles.textCards}`}>
        <div>
          <h3>{t("ALWAYS ON.")}</h3>
          <p>
            {t(
              "Cloud-based, full-service convenience puts your fields at your fingertips."
            )}
          </p>
        </div>
        <div>
          <h3>{t("ALWAYS ACTIONABLE.")}</h3>
          <p>
            {t(
              "AI-driven insights remove the guesswork. Be where you need to be, and take action."
            )}
          </p>
        </div>
        <div>
          <h3>{t("ALWAYS YOUR PARTNER.")}</h3>
          <p>
            {t(
              "Agronomic and retail experts understand your business and help exceed your goals."
            )}
          </p>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          marginBottom: "4rem",
          fontSize: "1.2rem",
          fontWeight: "bold",
        }}
      >
        <p>
          {t("Pick a plan at affordable per acre pricing.")}{" "}
          <NavLink
            to="/contact-us"
            style={{
              color: "var(--headings-color)",
              textDecoration: "underline",
            }}
          >
            {t("Let’s get started.")}
          </NavLink>
        </p>
      </div>

      {/* accordion section */}
      <div className={`container`} style={{ marginBottom: "2rem" }}>
        {/* at media query of min width 768px render accordion inside table */}
        <MediaQuery query="(min-device-width: 768px)">
          <table className={styles.infoTable}>
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: "var(--main-color)",
                    color: "var(--headings-color)",
                  }}
                >
                  {t("CAPABILITY")}
                </th>
                <th
                  style={{
                    backgroundColor: "var(--main-color)",
                    color: "var(--headings-color)",
                  }}
                >
                  {t("FEATURES")}
                </th>
                <th
                  style={{
                    backgroundColor: "var(--headings-color)",
                    color: "var(--main-color)",
                  }}
                >
                  {t("ESSENTIAL")}
                </th>
                <th
                  style={{
                    backgroundColor: "var(--headings-color)",
                    color: "var(--main-color)",
                  }}
                >
                  {t("ADVANCED")}
                </th>
                <th
                  style={{
                    backgroundColor: "var(--headings-color)",
                    color: "var(--main-color)",
                  }}
                >
                  {t("PRO")}
                </th>
              </tr>
            </thead>
            <tbody>
              <Accordionn />
            </tbody>
          </table>
        </MediaQuery>

        {/* at media query of max width 768px render accordion only */}
        <MediaQuery query="(max-device-width: 767px)">
          <Accordionn />
        </MediaQuery>
      </div>
    </>
  );
}
export default Solutions
